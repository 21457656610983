<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="anasale">
        <div class="anasale_tit">
            <el-switch
                    class="demo"
                    v-model='kaiguan'
                    @change="dianji_kaiguan"
                    active-color="#9a84db"
                    inactive-color="#c4c7cd"
                    active-text="ON"
                    inactive-text="OFF">
                </el-switch>
            <span>默认启用</span>
            此开关是针对全体员工是否显示销售模块，请慎重选择
        </div>
        <ul class="anasale_title">
            <li v-for="(i,index) in tit_list" v-show="index!=0" @click="dianji_tiaozhuan(index)" :key="index"><span :class="dangqian==index?'zise':''">{{i}}</span></li>
        </ul>
        <div class="anasale_con">
            <router-view />
        </div>
    </div>
</template>

<script>
import { update_module_status, query_module_status } from '../../api/api.js'
export default {
  name: 'anasale',
  data () {
    return {
      kaiguan: true,
      tit_list: ['方法选择', '分配规则', '预警规则', '角色规则'], // , '会员规则'
      dangqian: '1'
    }
  },
  mounted () {
  },
  created () {
    this.lujing(this.$route.path)
    this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      deep: true
    }
  },
  methods: {
    lujing (path) {
      if (path == '/anasalemethod') {
        this.dangqian = '0'
      } else if (path == '/anasalebranch') {
        this.dangqian = '1'
      } else if (path == '/anasalealert') {
        this.dangqian = '2'
      } else if (path == '/anasalekanban') {
        this.dangqian = '3'
      } else if (path == '/membership') {
        this.dangqian = '4'
      }
      this.path = path
    },
    jichu () {
      // 查询模块状态
      if (this.$ent_id() != undefined && this.$ent_id() != null && this.$ent_id().length != 0) {
        query_module_status({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            module_id: '2'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10189) {
            if (JSON.parse(res.data.body.data) == 1) {
              this.kaiguan = true
            } else if (JSON.parse(res.data.body.data) == 0) {
              this.kaiguan = false
            }
          } else if (res.data.code == 10190) {
          }
        })
      }
    },
    // 点击开关
    dianji_kaiguan () {
      this.update_module_status_aa()
    },
    // 修改模块状态
    update_module_status_aa () {
      let btn = ''
      if (this.kaiguan) {
        btn = '1'
      } else {
        btn = '0'
      }
      update_module_status({
        data: {
          module_id: '2',
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          active: btn
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10191) {
          this.$message({
            message: '修改成功',
            type: 'success'
          }) 
        } else if (res.data.code == 10192) {
          this.$message({
            message: '修改失败',
            type: 'error'
          })
        }
      })
    },
    shifou_qiyong () {

    },
    dianji_tiaozhuan (index) {
      if (index == '0' && this.path != '/anasalemethod') {
        this.$router.push('/anasalemethod')
      } else if (index == '1' && this.path != '/anasalebranch') {
        this.$router.push('/anasalebranch')
      } else if (index == '2' && this.path != '/anasalealert') {
        this.$router.push('/anasalealert')
      } else if (index == '3' && this.path != '/anasalekanban') { // 角色规则
        this.$router.push('/anasalekanban')
      } else if (index == '4' && this.path !== '/membership') { // 会员guize
        this.$router.push('/membership')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
